import React, { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { PickerArrowDown } from "../icons";
import { useCypressWarning } from "../../hooks";

const getBackgroundColour = (variant, disabled, error) => {
  if (disabled && variant === "light") {
    return "bg-base-50";
  } else if (disabled && variant === "dark") {
    return "bg-primary-400";
  } else if (error) {
    return "bg-error-50";
  }

  return "bg-white";
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchablePicker({
  id,
  title,
  error,
  helpText,
  placeholder = "Choose option from list",
  value,
  disabled = false,
  onChange,
  options,
  variant = "dark",
  tooltipMessage,
  ...props
}) {
  useCypressWarning(id, `id missing for ${title} picker component`);

  const labelTextClass = variant === "light" ? "text-black" : "text-white";
  const helpTextClass =
    variant === "light" ? "text-primary-800" : "text-primary-100";

  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) => {
          return option.title.toLowerCase().includes(query.toLowerCase());
        });

  const changeEvent = (option) => {
    setSelectedOption(option);
    onChange(option?.value);
  };

  function findSelectedOption(selectedValue) {
    if (selectedValue) {
      return options.find(({ value }) => value === selectedValue);
    }
    return null;
  }

  useEffect(() => {
    setSelectedOption(findSelectedOption(value));
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Combobox
      as="div"
      value={selectedOption}
      onChange={changeEvent}
      data-cy={`${id}-dropdown`}
      disabled={disabled}
      nullable
    >
      <Combobox.Label
        className={classNames("block text-sm font-medium", labelTextClass)}
      >
        {title}
      </Combobox.Label>
      <div className="relative mt-1 text-sm">
        <Combobox.Button
          as="div"
          onClick={() => {
            setQuery("");
          }}
        >
          <Combobox.Input
            className={classNames(
              "w-full py-2 pl-3 pr-10 bg-white border placeholder:text-base-500 text-base-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm",
              error ? "border-error-500" : "border-base-300",
              getBackgroundColour(variant, disabled, error)
            )}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(option) => option?.title}
            autoComplete="off"
            placeholder={placeholder}
          />
        </Combobox.Button>
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none">
          <PickerArrowDown aria-hidden="true" />
        </Combobox.Button>

        {filteredOptions.length > 0 && (
          <Combobox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions?.map((option) => (
              <Combobox.Option
                key={option.id}
                value={option}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none text-sm py-2 pl-3 pr-9",
                    active ? "bg-base-50 text-base-500" : "text-base-500"
                  )
                }
              >
                {() => (
                  <>
                    <span className="block truncate">{option.title}</span>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
      {helpText && (
        <p className={`text-xs ${helpTextClass} mt-3`}>{helpText}</p>
      )}
    </Combobox>
  );
}
