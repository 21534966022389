import { useIntl } from "react-intl";
import { getTranslationMessage } from "../libs/i18n";

export default function useProductNames() {
  const intl = useIntl();

  const boilerText = getTranslationMessage({
    intl,
    id: "products.boiler",
    defaultMessage: "boiler",
  });
  const controlText = getTranslationMessage({
    intl,
    id: "products.control",
    defaultMessage: "control",
  });
  const controllerText = getTranslationMessage({
    intl,
    id: "products.controller",
    defaultMessage: "controller",
  });
  const cylinderText = getTranslationMessage({
    intl,
    id: "products.cylinder",
    defaultMessage: "cylinder",
  });
  const filterText = getTranslationMessage({
    intl,
    id: "products.filter",
    defaultMessage: "filter",
  });
  const heatPumpText = getTranslationMessage({
    intl,
    id: "products.heatPump",
    defaultMessage: "heat pump",
  });
  const heatPumpWaterHeaterText = getTranslationMessage({
    intl,
    id: "products.heatPumpWaterHeater",
    defaultMessage: "heat pump water heater",
  });

  return {
    boilerText,
    controlText,
    controllerText,
    cylinderText,
    filterText,
    heatPumpText,
    heatPumpWaterHeaterText,
  };
}
