import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar = ({ percentage, target }) => {
  return (
    <div>
      <CircularProgressbarWithChildren
        value={percentage}
        styles={{
          path: {
            stroke: percentage === 100 ? "#95C11F" : "white",
            strokeLinecap: "rounded",
            transformOrigin: "center center",
          },
          trail: {
            stroke: "#8b868f",
            strokeLinecap: "rounded",
            transformOrigin: "center center",
          },
          text: {
            fill: "white",
            fontSize: "12px",
          },
        }}
      >
        <p className="text-xs md:text-base">{percentage}%</p>
        <p className="text-xs md:text-base">complete</p>
      </CircularProgressbarWithChildren>
      {target && (
        <p className="text-xs md:text-base text-center pt-4">
          {target}
          <br /> Registrations
        </p>
      )}
    </div>
  );
};

export default CircularProgressBar;
